.home {
  .home_banner {
    position: relative;
    width: 100%;
    height: auto;

    .frontdrop {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: rgb(255,255,255);
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
    }

    &-heading_1 {
      margin-bottom: 22px;
      text-align: center;
      font-size: pxToRem(22px);
      line-height: pxToRem(32px);
      font-weight: 500;
      color: $black-color;
      width: 80%;

      @include tablet-portrait {
        width: 80%;
        font-size: pxToRem(42px);
        line-height: pxToRem(62px);
      }

      @include tablet-landscape {
        width: 50%;
      }

      @include desktop {
        width: 844px;
        font-size: pxToRem(63px);
        line-height: pxToRem(90px);
      }

    }

    &--bg {
      position: absolute;
      width: 100%;
      height: 100%;

      .image {
        object-position: bottom center;
        width: 100%;
        height: 100%;
        background-color: $gray-color;

        @include desktop{
          width: 100%;
          height: 100%;
        }
      }
    }

    &--content_wrap {
      position: relative;
      z-index: 1;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 100px 14px 30px 14px;
      width: 100%;
      height: 100%;

      @include tablet-portrait {
        padding: 200px 32px 100px 32px;
      }
    }

    &--content {
      position: relative;
      display: flex;
      justify-content: center;
    }

    &--carousel {
      position: relative;
      padding-bottom: 106.67%;
      width: 80%;
      height: 0;

      @include tablet-portrait {
        padding-bottom: 60%;
        width: 80%;
      }

      @include tablet-landscape {
        padding-bottom: 37.5%;
        width: 50%;
      }

      @include desktop {
        padding-bottom: 0;
        width: 844px;
        height: 633px;
      }

      .frame {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        border: 10px solid $black-color;

        @include desktop {
          border-radius: 80px;
          border: 20px solid $black-color;
        }
      }

      .wrap {
        overflow: hidden;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        background-color: $black-color;

        @include desktop {
          border-radius: 80px;
        }

        .ant-carousel .slick-dots-bottom{
          bottom: 0;
          top: calc(75% - 14px);

          @include tablet-portrait{
            top: calc(70% - 20px);
          }

          @include desktop {
            top: calc(65% - 20px);
          }
        }
      }

      .section {
        position: relative;
        margin: 10px;
        padding-bottom: calc(133.33% - 20px);
        width: calc(100% - 20px) !important;
        height: 0;

        @include tablet-portrait {
          padding-bottom: calc(75% - 20px);
          width: calc(100% - 20px) !important;
        }

        @include desktop{
          margin: 20px;
          padding-bottom: calc(75% - 40px);
          width: calc(100% - 40px) !important;
        }

        &--bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &--content_wrap {
          position: absolute;
          top: 0;
          left: 0;
          padding: 22px 18px;
          width: 100%;
          height: 75%;

          @include tablet-portrait {
            padding: 22px 48px;
          }

          @include desktop{
            padding: 30px 125px;
          }
        }

        &--content {
          overflow: hidden;
          width: 100%;
          height: 100%;
        }

        &--title {
          margin-bottom: 12px;
          font-weight: 500;
          line-height: pxToRem(28px);
          font-size: pxToRem(22px);
          color: $brand-color;

          @include tablet-portrait {
            margin-bottom: 18px;
            line-height: pxToRem(52px);
            font-size: pxToRem(48px);
          }

          @include desktop{
            line-height: pxToRem(72px);
            font-size: pxToRem(62px);
          }
        }

        &--description {
          text-overflow: ellipsis;
          line-height: pxToRem(18px);
          font-size: pxToRem(14px);
          color: $white-color;

          @include desktop {
            line-height: pxToRem(28px);
            font-size: pxToRem(18px);
          }
        }
      }
    }

    .query_control {
      overflow: hidden;
      position: relative;
      z-index: 1;
      margin-top: -22vw;
      max-width: 1004px;
      width: calc(100% - 28px);
      border-radius: 30px;
      background-color: $white-color;
      box-shadow: 0 18px 26px -12px rgba(0, 0, 0, 0.5);

      @include tablet-portrait {
        margin-top: -106px;
        width: 100%;
      }

      @include desktop {
        margin-top: -220px;
        box-shadow: 0 48px 38px -42px rgba(0, 0, 0, 0.5);
      }

      &--head {
        width: 100%;
        height: 60px;
      }

      &--title_box {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 375px;
        width: 100%;
        height: 100%;
        border-radius: 0 30px 30px 0;
        background-color: $brand-low-lv-1-color;
      }

      &--title_box {
        text-align: center;
        font-size: pxToRem(18px);
        color: $black-color;
      }

      &--control {
        padding: 8px 14px 30px 14px;

        @include tablet-portrait {
          padding: 18px 22px 30px 22px;
        }

        @include desktop {
          padding: 18px 76px 30px 76px;
        }
      }

      &--search {
        display: flex;
        flex-wrap: wrap;
        height: 55px;

        .input_box {
          position: relative;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-right: 8px;
          padding-top: 10px;
          padding-bottom: 10px;
          width: calc(100% - 88px);
          height: 100%;
          border-bottom: 1px solid $gray-color;

          @include tablet-portrait {
            margin-right: 28px;
            width: calc(100% - 184px);
          }

          .icon {
            margin-right: 22px;
            width: auto;
            height: 17px;
            background: none;
          }

          .input {
            width: calc(100% - 34px);
            border: 0;
            font-size: pxToRem(16px);
            color: $black-color;
          }
        }

        .search_btn {
          @include brand-gradient;
          transition: trans(800ms);
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          width: 80px;
          height: 100%;
          border-radius: 27.5px;
          border: 0;

          @include on-event {
            outline: none;
            transform: translateX(10px);
          }

          @include tablet-portrait {
            margin-top: 0;
            width: 156px;
          }

          &--text {
            display: none;

            @include tablet-portrait {
              display: block;
              margin-right: 12px;
              font-weight: 500;
              font-size: pxToRem(18px);
              color: $white-color;
            }
          }

          &--icon {
            width: auto;
            height: 10px;
            background: none;
          }
        }
      }

      .query_result {
        .opensearch {
          position: relative;
          box-shadow: none;
        }
      }

      .popular_search {
        padding-top: 20px;

        &--title {
          margin-bottom: 20px;
          font-size: pxToRem(16px);
          color: $black-color;
        }

        &--box_wrap {
          @include tablet-portrait {
            display: flex;
            flex-wrap: wrap;
          }
        }

        &--box {
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          @include tablet-portrait {
            margin-right: 25px;
          }
        }

        &--image {
          overflow: hidden;
          margin-right: 6px;
          width: 50px;
          height: 50px;
          border-radius: 8px;

          .image {
            width: 100%;
            height: 100%;
          }
        }

        &--name {
          width: calc(100% - 56px);
          font-size: pxToRem(16px);
          color: $black-color
        }
      }
    }
  }

  .promotion_carousel {
    margin-bottom: 50px;

    &--heading_2 {
      padding-top: 30px;
      padding-bottom: 16px;
      text-align: center;
      font-weight: 500;
      font-size: pxToRem(28px);
      color: $black-color;

      @include tablet-portrait {
        font-size: pxToRem(36px);
      }
    }

    &--countdown {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 42px;

      .countdown_text {
        margin-top: 4px;
        margin-right: 6px;
        font-size: pxToRem(20px);
        color: $brand-color;
      }

      .ant-statistic-content-value {
        font-size: pxToRem(20px);
        color: $brand-color;
      }
    }

    &--carousel {
      .ant-carousel {
        .slick-slider {
          .slick-dots  {
            margin-left: 0 !important;
            margin-right: 0 !important;

            li {
              list-style-type: none;
            }
          }
        }
      }
    }

    &--box {
      overflow: hidden;
      position: relative;
      padding-bottom: 25.71428571%;
      width: 100%;
      height: 0;
    }

    &--image {
      position: absolute;
      width: 100%;
      height: 100%;

      .image {
        width: 100%;
        height: 100%;
      }
    }
  }

  .property_highlight {
    margin-bottom: 124px;

    @include tablet-portrait {
      margin-bottom: 88px;
    }

    .slide_wrap {
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      display: -webkit-inline-box;
      margin-top: -100px;
      padding-top: 100px;
      width: 100%;

      @include tablet-portrait {
        display: none;
      }

      .card {
        scroll-snap-align: start;
        padding-left: 10px;
        padding-right: 10px;
        width: 80%;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }
    }

    .column_wrap {
      display: none;

      @include tablet-portrait {
        display: block;
      }

      .card {
        margin-bottom: 36px;
      }
    }

    .viewall {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;

      @include desktop {
        margin-top: 0;
      }

      .link {
        display: flex;
        align-items: center;

        &--text {
          margin-right: 10px;
          font-size: pxToRem(16px);
          color: $brand-color;
        }

        &--icon {
          width: auto;
          height: 20px;
          background: none;
        }
      }
    }
  }

  .uniquely_lives {
    position: relative;
    background-color: black;

    &--bg {
      overflow: hidden;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .frontdrop {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(0,0,0);
        background: linear-gradient(
                      0deg,
                      rgba(0,0,0,1) 0%,
                      rgba(0,0,0,0) 100%
                    );
      }

      .image {
        opacity: 0.5;
        width: 100%;
        height: 100%;
      }
    }

    &--content {
      padding-top: 16px;
      padding-bottom: 48px;
    }

    &--heading_2 {
      margin-bottom: 30px;
      font-weight: 500;
      font-size: pxToRem(38px);
      color: $brand-color;

      @include tablet-portrait {
        font-size: pxToRem(48px);
      }
    }

    &--detail {
      margin-bottom: 50px;
      line-height: pxToRem(24px);
      font-size: pxToRem(16px);
      color: $white-color;

      @include tablet-portrait {
        line-height: pxToRem(30px);
        font-size: pxToRem(20px);
      }
    }

    .slide_wrap {
      overflow: hidden;

      .mobile {
        @include tablet-landscape {
          display: none;
        }
      }

      .slick-track {
        transition: trans(800ms);
      }

      .desktop {
        display: none;

        @media (min-width: 900px) {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }
      }

      .slick-dots {
        position: unset !important;
        margin-top: 50px;

        li {
          button {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 1px solid $white-color;

            &::before {
              font-size: 0;
            }
          }
        }

        .slick-active {
          button {
            background-color: $white-color;
          }
        }
      }

      .slide-arrow--left,
      .slide-arrow--right {
        outline: none;
        position: absolute;
        top: 120px;
        width: auto;
        height: 20px;
        background: none;

        @include on-event {
          outline: none;
        }
      }

      .slide-arrow--left {
        left: -15px;
      }

      .slide-arrow--right {
        right: -15px;
      }

      .slide {
        width: 100%;
        height: 100%;
      }

      .card-before {
        .uniquely_card {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }

      .card {
        padding-left: 12.5px;
        padding-right: 12.5px;
        width: 100%;

        &--active {
          width: 100%;

          @include tablet-landscape {
            transition: trans(200ms);
            width: 537px;
          }

          .uniquely_card {
          }
        }
      }
    }

    .project_link {
      display: flex;
      justify-content: center;
      margin-top: 18px;
      padding-left: 30px;
      padding-right: 30px;
      width: 100%;

      .link {
        display: flex;
        align-items: center;
        text-align: center;
        font-weight: 500;
        line-height: pxToRem(30px);
        font-size: pxToRem(20px);
        color: $white-color;
      }

      .icon {
        width: auto;
        height: 45px;
        margin-left: 15px;
      }
    }
  }

  .openhouse {
    padding-top: 50px;
    padding-bottom: 60px;
    background-color: $black-color;

    &--title {
      margin-bottom: 18px;
      font-weight: 500;
      font-size: pxToRem(48px);
      color: $brand-color;
    }

    &--sub_title {
      margin-bottom: 48px;
      font-weight: 400;
      font-size: pxToRem(18px);
      color: $white-color;
    }

    &--slide {
      position: relative;
      width: 100%;

      .desktop {
        position: relative;
        padding-left: calc(50% - 375px);
        width: 100%;

        @media (min-width: 992px) {
          padding-left: calc(50% - 495px);
        }

        @media (min-width: 1200px) {
          padding-left: calc(50% - 585px);
        }
      }

      .control-box {
        position: absolute;
        z-index: 1;
        top: 0;
      }

      .previous,
      .next {
        display: none;

        @media (min-width: 900px) {
          display: flex;
          align-items: center;
          padding-left: 46px;
          padding-right: 46px;
          height: 100%;
        }

        button {
          cursor: pointer;

          @include on-event {
            outline: none;
          }
        }

        .icon {
          width: auto;
          height: 20px;
        }
      }

      .previous {
        left: calc(50% - 428px);
        padding-left: 24px;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.54) 20%, rgba(0,0,0,1) 90%, rgba(0,0,0,1) 100%);

        @media (min-width: 992px) {
          left: calc(50% - 547px);
        }

        @media (min-width: 1200px) {
          left: calc(50% - 638px);
        }
      }

      .next {
        right: 0;
        padding-left: 28%;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.54) 10%, rgba(0,0,0,0.54) 100%);
      }

      .item {
        .mobile {
          @media (min-width: 900px) {
            display: none;
          }
        }

        .desktop {
          display: none;

          @media (min-width: 900px) {
            display: block;
          }
        }
      }
    }

    &--card {
      padding-left: 15px;
      padding-right: 15px;

      @media (min-width: 900px) {
        padding-left: 30px;
        padding-right: 30px;
      }

      .image__wrap {
        position: relative;
        margin-bottom: 25px;
        padding-bottom: 56.25%;
        width: 100%;
        height: 0;

        .image {
          position: absolute;
          margin: 0;
          width: 100%;
          height: 100%;
        }
      }

      .content__wrap {
        .title {
          margin-bottom: 14px;
          line-height: normal;
          font-weight: 400;
          font-size: pxToRem(14px);
          color: $brand-color;
        }

        .detail {
          line-height: normal;
          font-weight: 400;
          font-size: pxToRem(14px);
          color: $white-color;
        }

        .profile {
          display: flex;
          align-items: center;
          margin-top: 25px;

          .image {
            margin-right: 25px;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            border: 1px solid $white-color;
          }

          .info {
            .name,
            .lastest {
              font-weight: 400;
              font-size: pxToRem(14px);
              color: $white-color;
            }
          }
        }
      }
    }

    &--view_all {
      margin-top: 50px;

      .container {
        display: flex;
        justify-content: flex-end;
      }

      .link {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: pxToRem(16px);
        color: $brand-color;

        .icon {
          margin-left: 10px;
          width: auto;
          height: 20px;
        }
      }
    }

    &-cover {
      overflow: hidden;
      position: relative;
      margin-bottom: 25px;
      padding-bottom: 56.25%;
      width: 100%;
      height: 0;
      border-radius: 20px;

      .frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .how_it_works {
    position: relative;
    padding-top: 46px;
    padding-bottom: 45px;

    &--bg {
      overflow: hidden;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .image {
        object-position: right center;
        width: 100%;
        height: 100%;
        background: none;
      }
    }

    .step {
      &--heading_2 {
        margin-bottom: 46px;
        font-weight: 500;
        font-size: pxToRem(38px);
        color: $brand-color;

        @include tablet-portrait {
          font-size: pxToRem(48px);
        }
      }

      .item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 72px;

        &--order {
          width: 48px;
          margin-right: 26px;
          text-align: right;
          font-weight: 500;
          font-size: pxToRem(36px);
          color: $gray-low-color;
        }

        &--icon {
          margin-right: 10px;
          width: 18px;
          height: auto;
          background: none;
        }

        &--text {
          width: calc(100% - 102px);
          font-weight: 500;
          font-size: pxToRem(18px);
          color: $black-color;
        }
      }
    }

    &--learn_more {
      display: flex;
      justify-content: center;

      .learn_more_btn {
        transition: trans(800ms);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 50px;
        border-radius: 25px;
        background-color: $brand-color;

        @include on-event {
          transform: translateX(10px);
        }

        .text {
          margin-right: 30px;
          font-size: pxToRem(16px);
          color: $white-color;
        }

        .icon {
          width: auto;
          height: 15px;
          background: none;
        }
      }
    }
  }
}
