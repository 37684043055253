.switch {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 34px;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #DCF7E3;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    right: 4px;
    bottom: 4px;
    background-color: #2FA84F;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #FFE4E3;
  }
  input:checked + .slider:before {
    background-color: #EB3D2F;

  }

  input:focus + .slider {
    box-shadow: 0 0 1px #FFE4E3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(-65px);
    -ms-transform: translateX(-65px);
    transform: translateX(-65px);
  }
    .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }

  .online {
      position: absolute;
      color: #2FA84F;
      font-weight: lighter;
      top: 10px;
      left: 10px;
  }

  .offline {
      position: absolute;
      color: #EB3D2F;
      font-weight: lighter;
      top: 10px;
      right: 10px;
  }

.property-code {
    font-size: 0.8rem;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(255,255,255,0.4);
    border-radius: 30px;
    color: white;
    font-weight: 100;
    padding: 8px;
}
  .border-preview {
    border: 1px solid #d8d8d8;
  }
.page-active {
    background-color: $brand-color;
    padding: 5px 8px;
    border-radius: 3px;
    color: white;
}

.discount-label {
    color: white;
    font-size: 0.9rem;
    text-align: center;
    width: 48px;
    padding-top: 10px;
    padding-bottom: 25px;
    background-color: #FD9B0A;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 73%, 0 100%);;

    position: absolute;
    top: 0;
    right: 10px;
}


.banner-property {
  background-image: url('/images/banner-property.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.post-btn {
  background-color: white;
  color: $brand-color;
  padding: 10px;
  border-radius:6px;
  transition: all 0.3s ease-in-out;
  &:hover {
  box-shadow: 0 15px 15px -6px rgba(0,0,0,0.3);
  }
}

.dropdown-container {
  z-index: 1;
  top: 150%;
  left: 0;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}
    .list:hover {
        background-color: rgba(176,168,255,0.3);
      }
    .selected:hover {
      background-color: $brand-color;
      color: white;
    }


    .dropdown-container--mobile {
      z-index: 1;
      border: none;
      border-radius: 0;
      top: 100%;
      left: -10%;

      width: 100vw;
      height: 100vh;

      box-shadow: inset 0 10px 10px -12px rgba(0,0,0,0.2);
    }

.property-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-overflow {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}


    @media screen and (max-width:425px) {
    .banner-property {
      background-image: url('/images/mobile-post.png');
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
    }
    .h-50 {
      height: 50vh;
    }
  }
