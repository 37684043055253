.footer {
  position: relative;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  background-color: rgb(247, 247, 247);

  &--wrap {
    margin-bottom: 26px;

    @include desktop {
      margin-bottom: 0;
    }
  }

  &--title {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: pxToRem(14px);
    color: $black-color;

    @include desktop {
      margin-bottom: 26px;
    }
  }

  &--list_wrap {
    display: flex;

    @include desktop {
      display: block;
    }
  }

  &--list {
    margin-right: 22px;

    @include desktop {
      margin-bottom: 12px;
    }
  }

  &--text {
    line-height: pxToRem(20px);
    font-size: pxToRem(14px);
    color: $gray-color;

    img {
      width: auto;
      height: 20px;
      background: none;
    }
  }

  &--bottom {
    @include desktop {
      margin-top: 12px;
    }
  }

  &--social {
    margin-top: 12px;
    margin-bottom: 12px;

    @include desktop {
      justify-content: center;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .email {
    display: flex;
    flex-wrap: wrap;

    input {
      padding-left: 12px;
      padding-right: 12px;
      width: calc(100% - 50px);
      height: 50px;
      border: 0;
      background-color: $white-color;
      font-size: pxToRem(14px);
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border: 0;
      background-color: $gray-color;

      img {
        width: auto;
        height: 10px;
        background: none;
      }
    }
  }
}