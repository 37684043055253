.header_outline {
  transition: trans(400ms);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  padding-left: 14px;
  padding-right: 14px;
  width: 100%;
  height: $height-header-phone;
  background-color: $white-color;

  @include tablet-landscape {
    padding-left: 70px;
    padding-right: 70px;
    height: $height-header-tablet-landscape;
  }

  &--home {
    background: none !important;
  }

  &--overtop {
    background-color: $white-color !important;
  }

  &--wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    @include tablet-landscape {
      justify-content: space-between;
    }
  }

  &--section {
    display: flex;
    align-items: center;
    height: inherit;

    .box {
      display: none;

      @include tablet-landscape {
        display: flex;
        align-items: center;
      }

      &:nth-child(2) {
        margin-left: 0;
      }

      &--text {
        font-weight: 500;
        font-size: pxToRem(14px);
        color: $brand-color;
      }

      &--line_hor {
        width: 3px;
        height: 20px;
        background-color: $brand-color;
      }
    }
  }

  &--filter {
    display: none;

    &--show {
      @include tablet-landscape {
        display: flex;
        align-items: center;
        height: 50px;
        width: 100%;
      }
    }

    .autocomplete {
      .input_box {
        &--icon {
          position: absolute;
          top: 16.5px;
          padding-left: 12px;
          padding-right: 12px;
          width: auto;
          height: 17px;
          background: none;
        }

        &--input {
          padding-left: 35px;
          padding-right: 35px;
          width: 100%;
          height: 50px;
          border-radius: 4px;
          border: 1px solid $gray-border;
          text-overflow: ellipsis;
          font-size: pxToRem(16px);
        }
      }
    }

    .move_in,
    .date {
      display: flex;
      align-items: center;
      margin-left: 8px;
      padding-left: 8px;
      padding-right: 8px;
      height: 100%;
      border-radius: 4px 0px 0px 4px;
      border: 1px solid $gray-border;
      background-color: $white-color;
      font-size: pxToRem(16px);

      &--icon {
        margin-right: 8px;
        width: auto;
        height: 17px;
        background: none;
      }
    }

    .date {
      margin-left: -1px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 0px 4px 4px 0px;
    }

    .search_btn {
      @include brand-gradient;
      margin-left: 16px;
      width: 50px;
      height: 100%;
      border-radius: 4px;

      .icon {
        width: auto;
        height: 17px;
      }
    }
  }

  &--link_to_home {
    height: 100%;
  }

  &--logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;
    background-color: $brand-color;

    @include tablet-landscape {
      width: 75px;
      height: 75px;
    }

    picture,
    .image {
      width: auto;
      height: 100%;
      background: none;

      @include tablet-landscape {
        width: auto;
      }
    }
  }

  &--lang {
    .custom_dropdown {
      cursor: pointer;
      font-weight: 500;
      font-size: pxToRem(14px);
      color: $brand-color;
    }
  }

  .bar {
    position: absolute;
    left: 0;
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 6px;
    background-color: rgb(247, 247, 247);

    @include tablet-landscape {
      display: none;
    }

    .line {
      margin-bottom: 4.5px;
      width: 22px;
      height: 2.5px;
      background-color: $brand-color;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--close {
      .line {
        &:first-child {
          transform: rotate(45deg) translate(2px, 2px);
        }

        &:last-child {
          transform: rotate(-45deg) translate(2px, -3px);
        }
      }
    }
  }

  .navbar_fullscreen {
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 100;
    top: 60px;
    left: 0;
    padding: 60px 20px;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: white;

    @include tablet-landscape {
      display: none;
    }

    .normal,
    .is_login {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;

      ul {
        li {
          padding-top: 12px;
          padding-bottom: 12px;
          display: flex;
          justify-content: center;
        }
      }
    }

    .is_login {
      display: flex;
      flex-direction: column;

      .wrapper {
        display: flex;
        flex-direction: column;
      }

      a {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
        font-size: pxToRem(14px);
      }

      .header_outline--login {
        overflow: auto;
        margin-top: 22px;
        margin-bottom: 22px;

        .noti-icon {
          right: 5px;
          top: -7px;
        }
      }
    }
  }

  &--login {
    background-color: $brand-color;
    color: white;
    border: 1px solid $brand-color;
    height: 40px;
    width: 103px;
    padding: 6.4px 10px;
    font-size: 16px;
    border-radius: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.noti-icon {
  position: absolute;
  right: 3px; top: 13px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #58a459;
}
.dropdown-login {
  position: absolute;
  top: 120%;
  right: 0;
  border-radius: 1rem;
}
  .dropdown-list {
    width: 100%;
    padding: 1rem 0 1rem 1rem;
    font-weight: 300;
    &:hover {
      color: $brand-color !important;
      background-color: #edebfe;
    }
  }


.date_filter {
  &--title {
    font-size: pxToRem(14px);
  }

  .input_wrap {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .input_box {
      position: relative;
      display: flex;
      align-items: center;
      width: 145px;
      height: 35px;

      .input {
        position: absolute;
        left: 0;
        padding-left: 40px;
        padding-right: 40px;
        width: 100%;
        height: 100%;
        border-radius: 17.5px;
        border: 1px solid $gray-color;
        background-color: rgb(247, 247, 247);
        text-align: center;
        font-weight: 500;
        font-size: pxToRem(16px);
        color: $brand-color;
      }

      .minus,
      .plus {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 2px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 1px solid $gray-color;
        background: none;
        font-weight: 500;
        font-size: pxToRem(15px);
        color: $gray-color;
      }

      .minus {
        left: 10px;
      }

      .plus {
        right: 10px;
      }
    }

    .unit {
      margin-left: 24px;
      font-size: pxToRem(14px);
      color: $gray-color;
    }
  }
}

.ant-modal-root {
  .ant-modal-title {
    text-align: center;
  }

  input[type="text"], input[type="password"], input[type="email"], input[type="tel"] {
    width: 100%;
    height: 36px;
    border-radius: 3px;
    border: 1px solid #747474;
    padding: 8px 14px;
    margin-bottom: 32px;
  }

  .divine-line {
    display: flex;
    margin: 10px;

    .line {
      width: 100%;
      border: 0.5px solid #d8d8d8;
      align-self: center;
    }

    span {
      padding: 0 18px;
    }
  }
  

  .group-social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;

    .social-icon {
      background-color: transparent;
      width: 45px;
      height: 45px;
      margin-right: 22px;
    }
  
    .social-icon:last-child {
      margin-right: 0;
    }
  }

  a {
    text-decoration: underline;
    color: #367cf6;
  }

  .login {

    .submit-login {
      width: 100%;
      height: 45px;
      background-color: $brand-color;
      color: white;
      text-align: center;
      padding: 8px 0;
      border-radius: 8px;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.92px;
      margin-bottom: 25px;
    }
    
    .forgetpassword {
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
    
    .sign-up {
      font-size: 12px;
  
      a {
        text-decoration: underline;
        color: #367cf6;
      }
    }
  }
  
  .sign-up {

    .submit-signup {
      width: 100%;
      height: 45px;
      background-color: $brand-color;
      color: white;
      text-align: center;
      padding: 8px 0;
      border-radius: 8px;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.92px;
      margin-bottom: 25px;
    }

    .rule-group {
      margin-bottom: 30px;

      .rule-items {
        width: 100%;
        text-align: left;
        margin-left: 0 !important;
        margin-bottom: 20px;
      }
  
      .rule-items:last-child {
        margin-bottom: 0;
      }

      .login {
        font-size: 12px;
      }
    }
  }

  .term-of-use {
    .content {
      border: 1px solid #747474;
      overflow-y: auto;
      height: 499px;
      width: 100%;
      padding: 20px 12px;
      text-align: left;
      margin-bottom: 20px;

      p {
        font-size: 16px;
        margin-bottom: 24px;
      }

      ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
      }

      b {
        font-weight: bold;
      }

      h1 {
        font-size: 32px;
        font-weight: bold;
      }

      h2 {
        font-size: 28px;
        margin-bottom: 8px;
        font-weight: bold;
      }
    }

    .close-btn-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .close-btn {
        width: 140px;
        height: 45px;
        border-radius: 5px;
        border: 1px solid #747474;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.82px;
        color: #979797;
        text-align: center;
      }
    }
  } 

  .verification {
    text-align: left;

    .send-code, .verify, .complete-btn {
      width: 100%;
      height: 45px;
      background-color: $brand-color;
      color: white;
      text-align: center;
      padding: 8px 0;
      border-radius: 8px;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.92px;
      margin-bottom: 25px;
    }

    .verify:disabled {
      background-color: #b8aaff;
    }

    p {
      margin-bottom: 30px;
    }
    .group-text {
      margin-bottom: 28px;
    }

    .group-text p {
      margin-bottom: 4px;
    }
    .resend {
      margin-bottom: 30px;
      margin-top: 10px;
    }

    .login {
      text-align: center;
    }
  }
  .forgetpassword {
    text-align: left;

    small {
      font-size: 12px;
    }

    .resend {
      margin-bottom: 30px;
      margin-top: 10px;
    }

    .submit-login {
      width: 100%;
      height: 45px;
      background-color: $brand-color;
      color: white;
      text-align: center;
      padding: 8px 0;
      border-radius: 8px;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.92px;
      margin-bottom: 25px;
    }
  }

  .continue {

    img {
      width: 120px;
      min-height: 120px;
      border-radius: 50%;
      margin-bottom: 25px;
      margin-right: auto;
      margin-left: auto;
    }
    
    p {
      margin-bottom: 30px;
      font-size: 16px;
    }

    .continue-btn {
      width: 100%;
      height: 45px;
      background-color: $brand-color;
      color: white;
      text-align: center;
      padding: 8px 0;
      border-radius: 8px;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.92px;
      margin-bottom: 25px;
    }

    .login-another {
      font-size: 12px;

      a {
        text-decoration: underline;
        color: #367cf6;
      }
    }
  }
}