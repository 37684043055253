.property-detail {
  padding-top: $height-header-phone;
  $border-grey: #d8d8d8;
  $oragne-color: #fd9b0a;

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-three-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  p {
    @apply text-sm;
  }

  @include tablet-landscape {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: $height-header-tablet-landscape;
  }
  &--navbar {
    position: fixed;
    width: 100%;
    background: #f7f7f7;
    flex: 1;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    border: 1px solid $border-grey;
    font-size: pxToRem(14px);
    z-index: 1;

    padding: 0 24px;
    margin: auto;
    margin-top: 32px;
    @screen md {
      padding: 0;
      margin-top: 0;
    }

    &--content {
      max-width: 1195px;
      margin: auto;
      flex: 1 1;
      align-items: center;
      justify-content: space-between;
      height: 40px;
    }

    &--btn {
      height: 40px;
      justify-content: center;
      align-items: center;
      border: 1px solid $border-grey;
      border-top: 0;
      border-bottom: 0;
      cursor: pointer;
    }

    &--btn-fixed {
      padding-top: 60px;
      position: fixed;
      top: 0;
      right: 0;
      background: #fff;
      z-index: 2;
      width: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  .left-grid {
    @include desktop {
      width: 754px;
    }
  }

  &--body {
    .padding-on-mobile {
      @apply px-6 md:px-0;
    }

    width: 100vw;

    padding: 30px 0px 0 0px;
    margin: auto;
    margin-top: 32px;
    @screen md {
      // padding: 30px 100px 0 100px;
      margin-top: 0;
    }

    .head {
      &--title {
        font-weight: 500;
        line-height: pxToRem(40px);
        font-size: pxToRem(26px);
        color: $black-color;
      }
    }
    .label {
      background-color: rgb(234, 233, 255);
      color: $brand-color;
    }

    // .preview-img {
    //   width: 100%;
    //   height: 56.25%;
    //   aspect-ratio: 16/9;
    // }

    .canvas-ratio {
      width: 100%;
      margin: 0;
      display: block;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .border-grey {
      border: 1px solid $border-grey;
    }

    .chat--card {
      border: 1px solid $border-grey;

      div *:not(:last-child) {
        margin-bottom: 16px;
      }

      &--btn {
        padding: 6px 16px;
        border-radius: 4px;
        font-size: 0.875rem;
        color: black;
        border: 1px solid #979797;
        background-color: white;
        display: flex;
        align-items: center;

        @include on-event {
          background-color: rgb(247, 247, 247);
        }
      }
    }

    .flag {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 70%, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 70%, 0 100%);
      background-color: $oragne-color;
      display: flex;
      justify-content: center;
      color: $white-color;
      width: 48px;
      height: 60px;
    }

    .flag--small {
      @extend .flag;
      width: 16px;
      height: 22px;
    }

    .text-purple {
      color: #c6bbff;
    }

    .text-oragne {
      color: $oragne-color;
    }

    .photo-menu {
      width: 100%;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      color: white;

      &--btn {
        background: transparent;
        display: flex;
        align-items: center;
        padding: 10px 16px;
        border-radius: 4px;
        font-size: 0.875rem;

        color: white;
        border-color: white;
        border: 1px solid white;

        position: absolute;
        right: 10px;
        width: auto;
        bottom: 10px;

        @screen md {
          position: relative;
          right: inherit;
          bottom: inherit;
        }
      }
    }

    .icon-photo-menu {
      width: 15px;
      height: 15px;
    }

    .triangle-right {
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-left: 12px solid white;
      border-bottom: 6px solid transparent;

      &--black {
        @extend .triangle-right;
        border-left: 12px solid black;
      }
    }

    .unit-overview {
      @apply my-4 flex flex-wrap bg-light-gray;
      &--item {
        @apply px-4 py-2 w-2/5 md:w-1/5 font-bold;
      }
    }

    .icon {
      @apply object-contain bg-transparent;
    }

    .flex-column-center {
      @apply flex flex-column items-center justify-center;
    }

    .detail {
      @apply my-6;
      &--title {
        @apply font-bold mb-6 flex;
        font-size: pxToRem(18px);
      }

      &--content {
        @apply mb-4;
      }

      &.mb-0 {
        margin-bottom: 0;
      }

      &.mt-0 {
        margin-top: 0;
      }

      &--btn {
        padding: 8px 16px;
        border-radius: 6px;
        font-size: 0.875rem;
        color: black;
        border: 1px solid #979797;
        background-color: white;
        display: flex;
        align-items: center;

        @include on-event {
          background-color: rgb(247, 247, 247);
        }
      }
    }

    .grid-3 {
      @apply mt-4 flex flex-wrap;
      &--item {
        @apply w-full md:w-1/3;
      }
    }

    .text-gray {
      color: rgb(151, 151, 151);
    }

    .map-wrapper {
      position: relative;
      display: flex;
      height: 418.5px;
      width: 100%;
    }

    .border-line {
      position: absolute;
      top: 0;
      border: 1px solid #d8d8d8;
      border-bottom: 0;
      border-right: 0;
    }

    // normal slide
    .slide-container {
      padding: 0;
      background: #fff;

      .slick-slider {
        width: 100%;
        max-height: 424px;
      }

      .slick-slide img {
        margin: auto;
        width: 100%;
        height: 56.25%;
        max-height: 424px;
      }
      .slick-next {
        right: 10px;
      }

      .slick-prev {
        z-index: 2;
        left: 10px;
      }

      .slick-prev,
      .slick-next {
        width: 24px;
        height: 24px;
      }

      .slick-prev:before,
      .slick-next:before {
        display: none;
      }
    }

    //slide on compare table
    .slide-with-card {
      .slick-slide {
        max-width: 323px;
      }

      .slick-prev,
      .slick-next {
        width: 30px;
        height: 30px;
        top: 25%;
      }

      .slick-prev {
        left: -45px;

        @screen md {
          left: -25px;
        }
      }

      .slick-next {
        display: none !important;

        @screen md {
          display: block !important;
        }
      }

      .slick-prev:before,
      .slick-next:before {
        display: none;
      }

      .ant-table-thead {
        display: none;
      }

      .ant-table-tbody > tr > td {
        padding: 10px 16px;
      }

      .head-table .ant-table-tbody > tr > td {
        background-color: #f7f7f7;
      }
    }
  }

  .table-content {
    .price {
      margin-bottom: 22px;
      font-weight: 500;

      &--promotion {
        .og {
          margin-right: 6px;
          text-decoration: line-through;
          font-size: pxToRem(14px);
          color: rgba(176, 168, 255, 1);
        }

        .now {
          margin-right: 6px;
          font-size: pxToRem(14px);
          color: $yellow-color;
        }

        .per {
          font-size: pxToRem(12px);
          color: $gray-color;
        }
      }

      &--normal {
        .og {
          margin-right: 6px;
          font-size: pxToRem(14px);
          color: $brand-color;
        }

        .per {
          font-size: pxToRem(12px);
          color: $gray-color;
        }
      }
    }
  }
}

//global style for modal
.photo-modal-wrap {
  .modal-content {
    padding-top: 56.25%;
    position: relative;
    height: calc(100% - 80px);

    @screen md {
      height: auto;
    }

    &--object {
      position: absolute;
      top: 0;
    }
  }

  .slide-container-modal {
    padding: 0px;
    background: #fff;

    .canvas-ratio {
      width: 100%;
      margin: auto;
      display: block;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    // .slick-slide img {
    //   margin: auto;
    //   width: 100%;
    //   height: 56.25%;
    // }

    .slick-next {
      display: none !important;
      @screen md {
        right: -45px;
        display: block !important;
      }
    }

    .slick-prev {
      display: none !important;
      @screen md {
        left: -45px;
        display: block !important;
      }
    }

    .slick-prev,
    .slick-next {
      width: 30px;
      height: 30px;
    }

    .slick-prev:before,
    .slick-next:before {
      display: none;
    }

    .slick-prev {
      z-index: 2;
    }

    .slick-dots li {
      margin: 0;
    }
  }

  .ant-modal {
    top: 40px;
    @screen md {
      padding: 40px 0;
      top: 0;
      width: calc(100vw - 30%) !important;
    }

    &-close-x {
      &--btn {
        padding: 4px 30px;
        border-radius: 4px;
        font-size: 0.875rem;
        color: black;
        border: 1px solid #979797;
        background-color: white;
        display: flex;
        align-items: center;
        line-height: 24px;

        @include on-event {
          background-color: rgb(247, 247, 247);
        }
      }
    }
    .ant-modal-body {
      padding: 0;
      // height: 424px;

      @screen md {
        // height: calc(100vh - 80px);
      }
    }
  }
}

// shaed email from  mailgo
#mailgo {
  #m-title,
  #m-details,
  #m-open,
  #m-copy,
  .m-by {
    display: none;
  }
}

.slick-slider {
  img.icon {
    margin: auto;
    width: auto;
    height: auto;
  }
}
