a:hover {
  color: black;
  text-decoration: underline;
}

.setting-btn {
    background-color: #f7f7f7;
    border-radius: 3px;
    transition: all ease-in-out .3s;
    &:hover {
        background-color: #e9e9e9;
    }
}

.tab-container {
    background-color: #f7f7f7;
    position: sticky;
    top: 75px;
    z-index: 99;
}
    .each-tab {
      border-radius: 5px;
      padding: 8px 2px;
      color: black;
      cursor: pointer;
      &:hover {
        background-color: #e7e7e7;
      }
    }
    .tab-active {
        background-color: $brand-color;
        color: white;
        &:hover {
          background-color: $brand-color;
        }
    }
    .tab-disable {
      color: #9CA3AF;
      cursor:default;
      &:hover {
        background-color: #f7f7f7;
      }
    }

    .calendar {
      background-color: #f7f7f7;
      height: 40px;
      border-radius: 5px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .input-text-line {
        height: 40px;
        width: 100%;
        padding-left: 1rem;
        border-bottom: 1px solid #D8D8D8;
    }
    .input-text-box {
        height: 40px;
        width: 100%;
        padding-left: 1rem;
        background-color: #f7f7f7;
        border-radius: 5px;
    }
    .input-headline {
        width: 100%;
        padding-left: 1rem;
        color: $brand-color;
        font-size: 2.5rem;
        border-bottom: 1px solid #D8D8D8;
    }
    .input-headline::placeholder {
        color: $brand-color;
        opacity: 1;
        font-size: 2.5rem;
    }

    .input-discount {
        color: $brand-color;
        padding-left: 1em;
        font-size:  1.5rem ;
    }
    .span-headline {
      width: 73px;
    }
    .dropdown-input-container {
        z-index: 1;
        top: 110%;
        left: 0;
        border: 1px solid #d8d8d8;
        border-radius: 5px;
        max-height: 300px;
        overflow: auto;
        ::-webkit-scrollbar {
          display: none;
          width: 0;
          height: 0;
          background-color: transparent;
        }
      }
      .list:hover {
          background-color: rgba(176,168,255,0.3);
        }
      .selected:hover {
        background-color: $brand-color;
        color: white;
      }


    .open-box {
        background-color: #d8d8d8;
        cursor: pointer;
        transition: all ease-in-out 0.3s;
        &:hover {
            background-color: #dfdfdf;
        }
    }


    .checkbox-container {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      /* Hide the browser's default checkbox */
      .checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #fff;
        border: 1px solid $brand-color;
        border-radius: 4px;
      }
      .checkmark.error {
        border: 1px solid #eb3d2f;
      }

      /* On mouse-over, add a grey background color */
      .checkbox-container:hover input ~ .checkmark {
        border: 1px solid rgb(148, 138, 247);
      }

      /* When the checkbox is checked, add a blue background */
      .checkbox-container input:checked ~ .checkmark {
        background-color: $brand-color;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .checkbox-container input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .checkbox-container .checkmark:after {
        left: 8px;
        top: 3px;
        width: 8px;
        height: 14px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .description {
        border: 1px solid #d8d8d8;
     }

        .location-search-input {
          height: 45px;
          width: 100%;
          padding-left: 0.7rem;

          background-color: #f7f7f7;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        .search-icon {
          height: 45px;
          background-color: #f7f7f7;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
          .autocomplete-dropdown-container {
            margin-top: 5px;
            background-color: #fff;
            border: 1px solid #d8d8d8;
            border-radius: 5px;
            padding: 1rem;
            line-height: 1.7;
          }
          .suggestion-item .suggestion-item--active{
            padding: 1em 0;
          }

        .input-box-error {
            background-color: #ffe4e3;
            border: 1px solid #eb3d2f;
        }
        .input-dropdown-error {
          background-color: #ffe4e3;
          border: 1px solid #eb3d2f;

        }

        .delete {
            background-color: $brand-color;
            cursor: pointer;
            color: white;
            border-radius: 8px;
            font-weight: lighter;
            padding: 5px 10px;
            transition: all ease-in-out 0.3s;
            &:hover {
                background-color: rgb(106, 96, 250);
            }
        }
        .addmore {
            background: transparent;

        }
        .addmore::placeholder {
            color: black;
            opacity: 1;
        }

    .border-gray {
        border: 1px solid #979797;
    }

    .gift-image {
      width: 100%;
      padding-top: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      overflow: hidden;
      border-radius: 5px;
    }


    .radio-container {
      display: block;
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .radio-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom radio button */
    .radio-checkmark {
      position: absolute;
      left: 0;
      height: 16px;
      width: 16px;
      background-color: white;
      border-radius: 50%;
      border: 1px solid $brand-color;

    }

    /* On mouse-over, add a grey background color */
    .radio-container:hover input ~ .radio-checkmark {
      background-color: rgb(235, 232, 232);
    }

    /* When the radio button is checked, add a blue background */
    .radio-container input:checked ~ .radio-checkmark {
      background-color: white;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .radio-checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .radio-container input:checked ~ .radio-checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .radio-container .radio-checkmark:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $brand-color;
    }

    .rules-tab {
      transition: all ease-in-out 0.2s;
      border-bottom: 4px solid transparent;
      &:hover {
        color: #c2c2c2;
      }
    }
    .rules-tab-active {
      color: black;
      border-bottom: 4px solid $brand-color;
      &:hover {
        color: black;
      }
    }
    .-top-2 {
      top: -2px;
    }
    .top-3px {
      top: 3px;
    }
    .top-23 {
      top: 23px;
    }
    .top-21 {
      top: 21%;
    }

    .obilgation-content p {
      color: #979797;
      text-align: justify;
      line-height: 1.3;
      font-weight: lighter;
      font-size: 0.95rem;
    }

    .filltext {
      border-bottom: 1px dotted black;
      min-height: 1.5rem;
    }


    .payout-discription {
      border: 1px solid #d8d8d8;
      background-color: #f7f7f7;
      border-radius: 5px;
    }

    .contact-info {
      background-color: #f7f7f7;
      border: 1px solid #d8d8d8;
      border-radius: 5px;
    }
    .phone-input {
      background-color: #f7f7f7 !important;
      border: 1px solid #d8d8d8 !important;
      border-radius: 8px;
      height: 40px !important;
      width: 100% !important;
    }
    .phone-dropdown-input {
      border: 1px solid #d8d8d8 !important;
      border-radius: 8px !important;
      padding: 1rem 0 !important;
    }


    .modal-overlay{
      background-color: rgba(0,0,0,0.5);
      width: 100vw;
      height: 100vh;
      z-index: 999;
      position: fixed;
    }
        .modal-container {
          z-index: 9999;
          background-color: #fff;
          position: fixed;
          top: 50%;
          left: 50%;
          max-width: 430px;
          transform: translate(-50%,-50%);
          width: 50%;
          border-radius: 5px;
        }

      .setting-container--mobile {
        z-index: 999;
        border: none;
        border-radius: 0;
        top: 110%;
        width: 100vw;
        left: -5vw;
        position: absolute;
        height: 100vh;

        box-shadow: inset 0 10px 10px -12px rgba(0,0,0,0.2);
        p {
            padding: 1.5rem 1rem;
            cursor: pointer;
        }
        p:hover {
          background-color: rgba(176,168,255,0.3);
        }
      }

      .setting-container {
        position: absolute;
        z-index: 999;
        top: 110%;
        right: -50%;
        border: 1px solid #d8d8d8;
        border-radius: 5px;
        width: max-content;
      }
      .setting-container p {
          padding: 1rem 0.5rem;
          cursor: pointer;
      }
        .setting-container p:hover {
          background-color: rgba(176,168,255,0.3);
        }
        .selected {
          background-color: $brand-color;
          color: white;
        }
        .selected:hover {
          background-color: $brand-color;
          color: white;
        }


        .preview-lg {
          width: 300px;
          top: 170px;
          // right: 50%;
          // transform: translateX(170%);
          &-fixed {
            position: fixed;
          }
          &-ab {
            position: absolute;
            bottom: 100px;
          }
        }
        // .upload {
        //   margin: 1.5rem 0.5rem 0 0.5rem;
        // }
        .upload button {
          background-color: $brand-color;
        }
            .delete-upload-image {
              right: 0;
            }

        .upload-error {
          background-color: #fceded;
          border: 1px solid #eb3d2f;
          color: #eb3d2f ;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          text-align: center;
          height: 169px;
        }
            .error-delete-btn {
              position: absolute;
              bottom: 0.5rem; right: 1rem;
            }
            .upload-here {
              width: 100%;
            }
        .upload-container span{
          display: flex;
          flex-direction: column-reverse;
          width: 100%;
        }
          .ant-upload-list.ant-upload-list-picture-card {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-items: stretch;
          }
          .ant-upload-list-picture-card-container {
            width: 33%;
            height: auto;
            padding:0 0.5rem ;
            margin: 0;
            margin-top: 1.5rem;
          }
          .ant-upload.ant-upload-select-picture-card {
            min-height: 169px;
            width: 33%;
            height: auto;
            padding:0 0.5rem ;
            margin: 0;
            margin-top: 1.5rem;
            border: none;
            position: relative;
          }
          .ant-upload.ant-upload-select-picture-card span {
            background-color: #7053ff;
          }
.autocomplete-dropdown-project {
  margin-top: 5px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  padding: 1rem;
  line-height: 1.7;
  max-width: 400px;
  max-height: 300px;
  overflow: scroll;
  position:absolute;
  width: 100%;
  z-index: 1;
  p:hover {
    background-color: #f7f7f7;
    cursor: pointer;
  }
}
.box {
  min-height: 68px;
}

          .word-break {
            word-break: break-all;
          }

.discountPrice {
 width: 50%!important;

}
.discountPrice > div > .input-text-line{
  height: 25px !important;
  padding-bottom: 4px;
}

.discount-label-months {
  color: white;
  font-size: 1.3rem;
  text-align: center;
  width: 48px;
  padding-top: 10px;
  padding-bottom: 25px;
  background-color: #FD9B0A;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 73%, 0 100%);;
}

.description::placeholder {
  color: black;
}
.description-promotion textarea.ant-input::placeholder {
color: black;
}
@media screen and (max-width: 900px) {
    .tab-container {
      top: 60px;
    }
}
@media screen and (max-width: 768px) {
  .description {
    width: 100%;
  }
  .modal-container {
     width: 90%;
  }
  .ant-upload-list-picture-card-container {
    width: 50%;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 50%
  }
  .ant-upload-draggable-list-item img {
    width: 100%;
  }

}

@media screen and (max-width:767px) {
  .ant-upload-list-picture-card-container {
    width: 100%;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
  }
  .upload {
    padding: 2.5rem 0;
  }
}
