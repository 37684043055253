.uniquely_card {
  &--main {
    transition: trans(800ms);
    overflow: hidden;
    position: relative;
    padding-bottom: 100%;
    width: 100%;
    height: 0;
    border-radius: 18px;

    @media (min-width: 900px) {
      width: 50%;
      padding-bottom: 50%;
    }

    &__large {
      @include tablet-landscape {
        width: 100%;
        padding-bottom: 56.25%;
      }
    }
  }

  &--cover {
    position: absolute;
    width: 100%;
    height: 100%;


    // TODO
    .frontdrop {
      display: none;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(0,0,0);
      background: linear-gradient(
                    0deg,
                    rgba(0,0,0,1) 0%,
                    rgba(0,0,0,0) 100%
                  );
    }

    .image {
      width: 100%;
      height: 100%;
    }
  }

  &--content {
    display: none;
    position: absolute;
    z-index: 1;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0 20px 25px 20px;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(
                  0deg,
                  rgba(0,0,0,1) 0%,
                  rgba(0,0,0,0) 100%
                );

    .name,
    .price,
    .location {
      margin-top: 12px;
      line-height: normal;
      font-weight: 400;
      font-size: pxToRem(14px);
      color: $white-color;
    }

    .location {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .icon {
        margin: 0;
        margin-right: 10px;
        width: 8px;
        height: auto;
      }
    }
  }

  // TODO
  // &--content {
  //   display: block;
  //   position: absolute;
  //   z-index: 1;
  //   display: flex;
  //   flex-direction: column;
  //   flex-wrap: wrap;
  //   justify-content: flex-end;
  //   padding: 0 20px 25px 20px;
  //   width: 100%;
  //   height: 100%;

  //   @media (min-width: 900px) {
  //     padding: 0 20px 25px 40px;
  //   }

  //   &__wrap {
  //     @media (min-width: 900px) {
  //       display: flex;
  //       justify-content: space-between;
  //     }
  //   }

  //   .detail_label {
  //     @include brand-gradient;
  //     transition: trans(400ms);
  //     opacity: 0;
  //     overflow: hidden;
  //     // display: flex;
  //     display: none;
  //     align-items: center;
  //     justify-content: center;
  //     margin-top: 25px;
  //     padding-left: 32px;
  //     padding-right: 32px;
  //     width: fit-content;
  //     height: 0;
  //     border-radius: 25px;

  //     &__show {
  //       opacity: 1;
  //       height: 50px;
  //     }

  //     .text {
  //       font-weight: 400;
  //       font-size: parse(16px);
  //       color: $white-color;
  //     }

  //     .icon {
  //       margin-left: 5px;
  //       width: 17px;
  //       height: auto;
  //     }
  //   }
  // }

  &--name {
    margin-bottom: 8px;
    font-size: pxToRem(14px);
    color: $white-color;
  }

  &--price {
    margin-bottom: 8px;
    font-size: pxToRem(14px);
    color: $white-color;
  }

  &--location {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: pxToRem(14px);
    color: $white-color;

    .icon {
      margin: 0;
      margin-right: 8px;
      width: auto;
      height: 10px;
    }
  }

  &--promotion {
    opacity: 0;
    transform: translateY(-22px);
    overflow: hidden;
    transition: trans(800ms);
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0;
    height: auto;
    border-radius: 10px;
    background-color: $brand-color;

    padding: 10px;
    max-height: 300px;

    @include tablet-landscape {
      padding: 20px;
    }

    &--active {
      opacity: 1;
      transform: translateY(0);
    }

    .title {
      font-size: pxToRem(14px);
      color: $white-color;
    }

    .list_wrap {
      padding-left: 22px;
    }

    .list {
      list-style: disc;
      font-size: pxToRem(14px);
      color: $white-color;
    }
  }

  &--detail {
    animation: 1s showPromotionList cubic-bezier(0.16, 1, 0.3, 1) forwards;
    overflow: hidden;
    margin-top: 10px;
    padding: 10px 40px;
    width: 100%;
    height: auto;
    border-radius: 10px;
    background: rgb(112,83,255);
    background: linear-gradient(
                  51deg,
                  rgba(112,83,255,1) 0%,
                  rgba(169,139,255,1) 100%
                );

    @keyframes showPromotionList {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    .title {
      margin-top: 22px;
      font-weight: 400;
      line-height: pxToRem(22px);
      font-size: pxToRem(14px);
      color: $white-color;
    }

    ul {
      li {
        list-style: disc;
        list-style-position: inside;
        font-weight: 400;
        line-height: pxToRem(22px);
        font-size: pxToRem(14px);
        color: $white-color;
      }
    }
  }
}