// #openModalImage ul.memu {

// }

.slick-slider {
    width: 100%;

    img {
        margin: auto;
        width: auto;
        height: 45vh;
    }
}

.slider-prev {
    display: none;

    @include tablet-landscape {
        left: -70px;
        font-size: 10px;
        position: absolute;
        top: 50%;
        width: 20px;
        height: 20px;
        transform: translate(0, -50%);
        cursor: pointer;
        color: black;
        outline: none;
        background: transparent;
        border: 1px solid #d8d8d8;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14px;
    }
}

.slider-next {
    display: none;

    @include tablet-landscape {
        right: -70px;
        font-size: 10px;
        position: absolute;
        top: 50%;
        width: 20px;
        height: 20px;
        transform: translate(0, -50%);
        cursor: pointer;
        color: black;
        outline: none;
        background: transparent;
        border: 1px solid #d8d8d8;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14px;
    }
}
.properties {
    flex-wrap: wrap;
}

.vr {
    width: 100%;
    height: 455px;
}

@media (min-width: 576px) {
    .properties {
        flex-wrap: nowrap;
    }
    
    .vr {
        height: 565px;
    }
}
