.text-purple {
  color: $brand-color;
}
.text-light-purple {
  color: rgba(176, 168, 255, 1) ;
}
.text-white {
  color: white;
}
.search-input {
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding-left: 8px;
  padding-right: 8px;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  background-color: white;
  font-size: 1rem;
}
.property-shadow {
  padding-right: 8px;
}
.property-shadow:hover {
  box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.20);
  padding-right: 0px;
  border-right: 8px solid #7053FF;
}
.property-shadow-highlight {
  box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.20);
  padding-right: 0px;
  border-right: 8px solid #7053FF;
}

.property-shadow {
  border-radius: 8px;
}

.navbar-calendar {
    background-color: #fff;
    border: none;
    padding: 0;
  }
  .ant-picker-input > input {
    font-size: 1rem;
  }
  .ant-picker-input > input::placeholder {
    color: #9da4b0;

  }
  .navbar-search_btn {
    background: rgb(114,85,255);
    background: linear-gradient(0deg, rgba(114,85,255,1) 00%, rgba(168,139,255,1) 100%);
    &:hover {
        background: linear-gradient(0deg, rgb(121, 95, 253) 00%, rgb(174, 148, 252) 100%);
    }
}

.overlay-morefilter {
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
}
    .morefilter-modal-container {
        background-color: white;
        width: 50%;
        z-index: 9999999;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
      .border-bottom {
        border-bottom: 1px solid #979797;
      }
      .border-top {
        border-top: 1px solid #979797;
      }
          .each-type-container {
            border-bottom: 1px solid #e5e5e5;
          }
          .checkbox-container {
            display: block;
            position: relative;
            padding-left: 35px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }

          /* Hide the browser's default checkbox */
          .checkbox-container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          /* Create a custom checkbox */
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #fff;
            border: 1px solid $brand-color;
            border-radius: 4px;
          }
          .checkmark.error {
            border: 1px solid #eb3d2f;
          }

          /* On mouse-over, add a grey background color */
          .checkbox-container:hover input ~ .checkmark {
            border: 1px solid rgb(148, 138, 247);
          }

          /* When the checkbox is checked, add a blue background */
          .checkbox-container input:checked ~ .checkmark {
            background-color: $brand-color;
          }

          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }

          /* Show the checkmark when checked */
          .checkbox-container input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the checkmark/indicator */
          .checkbox-container .checkmark:after {
            left: 8px;
            top: 3px;
            width: 8px;
            height: 14px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          .filter-container {
            max-height: 500px;
            overflow: auto;
          }

    .popover-container {
      position: absolute;
      z-index: 99;
      -webkit-box-shadow: -8px 9px 40px -12px rgba(0,0,0,0.58);
      -moz-box-shadow: -8px 9px 40px -12px rgba(0,0,0,0.58);
      box-shadow: -8px 9px 40px -12px rgba(0,0,0,0.58);

      top: 110%;
      left: 0;
      background-color: #fff;
      border-radius: 10px;
      border: 1px solid #d8d8d8;
      width: 300px;
    }
        .horizontal-slider {
          position: relative;
          width: 100%;
          max-width: 500px;
          height: 50px;
      }
      .example-thumb {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: transparent;
        font-size: 1em;
        width: 13px;
        height: 13px;
        text-align: center;
        background-color: $brand-color;
        border-radius: 100%;
        cursor: pointer;
        box-sizing: border-box;
    }
    .example-track-2 {
        top: 23px;
        height: 3px;
        background: #d8d8d8;
    }
    .example-track-1 {
        top: 23px;
        height: 3px;
        background: #d8d8d8;
    }
    .example-track-0 {
        top: 23px;
        height: 3px;
        background: #d8d8d8;
    }

  .price-container {
    background-color: #f7f7f7;
    border: 1px solid #979797;
    border-radius: 3rem;
    padding: 5px;
  }

  .updown-container {
    background-color: #f7f7f7;
    border: 1px solid #979797;
    color: #979797;
    line-height: 1;
    font-size: 1.2rem;
    padding: 0 7px;
    cursor: pointer;
  }
  .pinmap {
    width: 30px;
    transform: translateY(-50%);
  }
  .map-container {
    background-color: white;
    transform: translate( -5px ,-50%);
    font-family: $font-main;
    z-index: 99;
  }
    .map-price p {
      font-size: 1.2rem;
    }
    .map-price span {
      font-size: 1rem;
    }
      .rent-price {
        color: $brand-color;
        &-discount {
          color: rgba(176, 168, 255, 1);
        }
      }
      .discount-price {
        color: $yellow-color;
      }

      .map-discount-label {
        width: 13px;
        height: 18px;
        background-color: #FD9B0A;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 73%, 0 100%);;

        position: absolute;
        top: -4px;
        right: 0px;
    }

    .map-arrow-down {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;

      border-top: 8px solid #fff;
      position: absolute;
      bottom: -8px;
      left: 15px;
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000);
    }

  .openfilter-mobile {
    background-color: white;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0; left: 0;
    z-index: 99;
    overflow: scroll;
  }

  .opensearch {
    background-color: white;
    position: absolute;
    top: 110%; left: 0;
    z-index: 99;
    max-height: 350px;
    width: 100%;
    overflow: auto;
      }
      .opensearch::-webkit-scrollbar-track {
          background-color: transparent;
      }

  .opensearch-mobile {
    background-color: white;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 100%; left: 0;
    z-index: 99;
    overflow: scroll;
    box-shadow: inset 0 5px 6px -5px rgba(0,0,0,0.1);
    }
    .each-search-mobile:hover {
      background-color: $brand-color;
      color: white;
      border-radius: 8px;
    }


  .map-mobile-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }
        .show-propertycard-mobile {
          width: 90%;
          background-color: white;
          z-index: 99;
          position: absolute;
          bottom: 15% ;
          left: 50%;
          transform: translateX(-50%);
        }
          .price-unclick {
            width: 80px;
            background-color: white;
            color: #858585;
            padding: 5px 0;
            p {
              font-size: 1rem;
            }
          }

        .arrow-down-mobile {
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid #fff;
          position: absolute;
          bottom: -8px;
          left: 20px;
          transform: translateX(-50%);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000);
        }

        .map-discount-label-mobile-unclick {
          width: 9px;
          height: 12px;
          background-color: #FD9B0A;
          clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 73%, 0 100%);;

          position: absolute;
          top: 0px;
          right: 2px;
        }

.head-container-filter-mobile {
  background-color: #C6BBFF;
  p {
    color: $brand-color;
    font-weight: lighter;
  }
}
  .close-filter-mobile {
    background-color: $brand-color;
    border-radius: 8px;
    color: white;
  }
    .collapse {
      background-color: #f7f7f7;
      border-bottom: 1px solid #d8d8d8;
      padding: 2rem 1.5rem;
      display: flex;
      justify-content: space-between;
      font-size: 1.1rem;
      font-weight: lighter;
    }



    .site-calendar-demo-card {
      border: 1px solid #e8e8e8;
      border-radius: 3px;
    }

    .ant-picker-calendar-header {
      color: $brand-color;
      .ant-radio-group {
        display: none;
      }
    }
    .ant-picker-content {
      thead {
        tr {
          height: 30px;
          border-bottom: 1px solid #e8e8e8;
          padding: auto;
          margin: auto;
          th {
            color: #979797;
            padding: auto;
            margin: auto;
          }
        }
      }
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: $brand-color;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: $brand-color;
      box-shadow: none;
    }
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before  {
      border: 1px solid #EAE9FF;
      background: #EAE9FF;
      z-index: -99;
      .ant-picker-calendar-date-value {
      color: $brand-color;
       }
    }
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
      background: #EAE9FF;
      color: $brand-color;
    }

.ant-select .ant-select-auto-complete .autocomplete .ant-select-single .ant-select-customize-input .ant-select-show-search {
  width: 100%;
}

.ant-select {
  width: 100%;
}

.property-autocomplete .ant-select-selection-search-input {
  display: flex;
  width: 100%;
}
 .input_box--input {
   background-color: transparent;
   width: 100%;
 }


 .title-apartment {
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }



 @media screen and (max-width:599px) {
  .arrow-desktop {
    visibility: hidden;
  }
  .arrow-mobile {
    z-index: 1;
    background-color: rgba(0,0,0,0.5);
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28%;
  }
}

@media screen and (min-width:600px) and (max-width:899px) {
  .arrow-desktop {
    visibility: visible;
  }
  .arrow-mobile {
    visibility: hidden;
  }
}

 @media screen and (min-width:900px) and (max-width:1439px) {
  .arrow-desktop {
    visibility: hidden;
  }
  .arrow-mobile {
    z-index: 1;
    background-color: rgba(0,0,0,0.5);
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28%;
  }
}

@media screen and (min-width:1440px)  {
  .arrow-desktop {
    visibility: visible;
  }
  .arrow-mobile {
    visibility: hidden;
  }
}
