.header-fixed {
  position: fixed;
  z-index: 11;
  width: 39%;
  padding: 0 15px;
  background-color: white;
}

@media screen and (max-width: 900px) {
  .header-fixed {
    width: 100%;
  }
}

.property {
  padding-top: $height-header-phone;

  @include tablet-landscape {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: $height-header-tablet-landscape;
  }

  &--left {
    @include tablet-landscape {
      width: 39vw;
    }
  }

  &--right {
    position: fixed;
    right: 0;
    display: none;
    background-color: $gray-low-color;

    @include tablet-landscape {
      display: block;
      width: calc(60vw);
      height: calc(100vh - 75px);
    }
  }

  .filter_phone {
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    height: 50px;

    &--wrap {
      position: fixed;
      z-index: 100;
      top: $height-header-phone;
      left: 0;
      display: flex;
      justify-content: center;
      padding-top: 8px;
      padding-bottom: 8px;
      width: 100%;
      background-color: $white-color;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);

      @include tablet-landscape {
        display: none;
      }
    }

    .autocomplete {
      position: absolute;
      margin-right: 8px;
      width: calc(100% - 30px);
      height: 100%;

      .ant-select-selector,
      .ant-select-selection-search-input {
        height: 100%;
      }

      .input_box {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

        .icon {
          position: absolute;
          margin-left: 11px;
          width: auto;
          height: 17px;
          background: none;
        }

        input {
          padding-left: 35px;
          padding-right: 7px;
          width: calc(100% - 102px);
          height: 50px;
          border-radius: 4px;
          background-color: rgb(247, 247, 247);
          text-overflow: ellipsis;
          font-size: pxToRem(14px);
          color: $black-color;
        }
      }

      &--btn {
        position: absolute;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        width: 80px;
        height: 50px;

        .text {
          font-size: pxToRem(14px);
          color: $brand-color;
        }

        .icon {
          width: 24px;
          height: auto;
        }
      }
    }
  }

  .head {
    position: fixed;
    margin-top: 55px;
    padding-top: 7px;
    padding-bottom: 10px;
    border-bottom: 1px solid $gray-border;

    @include tablet-landscape {
      margin-top: 0;
      padding-top: 16px;
      padding-bottom: 28px;
    }

    &--title {
      font-weight: 500;
      line-height: pxToRem(30px);
      font-size: pxToRem(20px);
      color: $black-color;
    }

    &--result {
      margin-top: 10px;
      font-size: pxToRem(14px);
      color: $gray-color;
    }

    .filter {
      display: none;

      @include tablet-landscape {
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
      }

      &--btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        padding: 6px 12px;
        border-radius: 4px;
        font-size: pxToRem(16px);
        color: $gray-color;
        border: 1px solid $gray-color;
        background-color: $white-color;

        @include on-event {
          background-color: rgb(247, 247, 247);
        }

        &--active {
          border: 1px solid $brand-color;
          color: $brand-color;
          background-color: rgb(247, 247, 247);

          &:after {
            content: '○';
            position: absolute;
            right: -5px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            font-size: pxToRem(10px);
            color: $yellow-color;
            background-color: $yellow-color;
          }
        }
      }

      &--clear {
        background: none;
        text-decoration: underline;
        font-size: 16px;
        color: $gray-color;
      }
    }
  }

  .property-list {
    padding-top: 9rem;
    padding-bottom: 22px;
    width: 100%;

    @include tablet-landscape {
      padding-top: 12rem;
      padding-bottom: 40px;
    }

    &--title {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: pxToRem(20px);
    }

    &--subtitle {
      font-size: pxToRem(14px);
    }

    &--card {
      .feature_list {
        margin-bottom: 25px;

        @include tablet-landscape {
          margin-bottom: 30px;
        }

        .card {
          padding-top: 22px;
          padding-bottom: 22px;
          border-bottom: 1px dashed $gray-border;

          &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
          }
        }
      }

      .project_list {
        position: relative;
        width: 100vw;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: $black-color;

        @media (min-width: 576px) {
          left: 0;
          width: 100%;
        }

        @include tablet-landscape {
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          padding-top: 28px;

          padding-bottom: 15px;
        }

        .icon {
          display: flex;
          flex-wrap: wrap;
          width: fit-content;

          &--text {
            padding: 4px 2px;
            border-radius: 5px;
            background-color: rgb(234, 233, 255);
            font-weight: 500;
            font-size: pxToRem(14px);
            color: $brand-color;
          }

          &--label {
            margin-left: 3px;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 70%, 0 100%);
            width: 17px;
            height: inherit;
            background-color: $yellow-color;
          }
        }

        .heading {
          margin-bottom: 10px;

          &--2 {
            margin-top: 12px;
            margin-bottom: 12px;
            text-align: center;
            font-weight: 500;
            font-size: pxToRem(20px);
            color: $brand-color;

            @include tablet-landscape {
              margin-top: 0;
            }
          }

          &--subtitle {
            text-align: center;
            font-size: pxToRem(14px);
            color: $white-color;
          }
        }

        .page {
          display: none;

          @include tablet-landscape {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 15px;
            border: 1px solid $white-color;
            font-size: pxToRem(12px);
            color: $white-color;
          }
        }

      }

      .latest_list {
        padding-top: 16px;
        padding-bottom: 16px;

        @include tablet-landscape {
          padding-top: 24px;
          padding-bottom: 24px;
        }

        .heading {
          margin-bottom: 20px;

          @include tablet-landscape {
            margin-bottom: 24px;
          }

          &--2 {
            margin-bottom: 10px;
            font-weight: 500;
            font-size: pxToRem(20px);
          }

          &--subtitle {
            line-height: pxToRem(21px);
            font-size: pxToRem(14px);
          }
        }

        .list_wrap {
          .card {
            margin-bottom: 25px;
          }
        }
      }
    }

    &--pagination {
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .search {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 12px;
        width: 70px;
        height: 24px;

        @include tablet-portrait {
          width: 80px;
        }

        .icon {
          position: absolute;
          z-index: 1;
          margin-left: 5px;
          width: auto;
          height: 10px;
        }

        .input {
          position: absolute;
          padding-left: 25px;
          padding-right: 10px;
          width: 100%;
          height: 100%;
          background-color: rgb(247, 247, 247);
          font-size: pxToRem(12px);
        }
      }
    }
  }
}
