html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  outline: none;
}

img {
  object-fit: cover;
  // background-color: $gray-color;
  font-size: 0;
}

#googlemap {
  img {
    background-color: transparent !important;
  }
}

input {
  padding: 0;
  border: 0;
}

button {
  cursor: pointer;
  padding: 0;
  border: 0;
}

a {
  @include on-event {
    text-decoration: none !important;
    color: $brand-color;
  }
}

.icon {
  background: none;
}

.lazyload-wrapper {
  width: 100%;
  height: 100%;
}

.primary-btn {
  background-color: $brand-color;
  color: white;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: rgb(102, 75, 238);
  }
}

.light-btn {
  background-color: #786fff;
}

.gray-btn {
  background-color: #d8d8d8;
  border-radius: 3px;
}

.light-gray-btn {
  background-color: #D8D8D8;
  color: white;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #d1d1d1;
  }
}

::-webkit-input-placeholder {
  background-color: transparent;
  color: black;
}

.text-brand {
  color: $brand-color;
}

.bg-brand {
  background-color: $brand-color;
  color: white;
}

.text-gray {
  color: rgb(151, 151, 151);
}

.bg-brand-base {
  background-color: #eae9ff;
}

.cursor-pointer {
  cursor: pointer;
}
