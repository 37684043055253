$font-main: 'Prompt', sans-serif;

$brand-color: rgb(112, 83, 255);
$brand-low-lv-1-color: rgb(234, 233, 255);
$black-color: rgb(0, 0, 0);
$white-color: rgb(255, 255, 255);
$gray-color: rgb(151, 151, 151);
$gray-low-color: rgb(195, 195, 195);
$yellow-color: rgb(253, 155, 10);
$gray-border: rgb(216, 216, 216);

@mixin brand-gradient {
  background: rgb(176, 168, 255);
  background: linear-gradient(
                90deg,
                rgba(176, 168, 255, 1) 0%,
                rgba(120, 111, 255, 1) 100%
              );
}

$height-header-phone: 60px;
$height-header-tablet-landscape: 75px;