@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;

// 1. Configuration and helpers
@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';

// 2. Vendors
@import 'vendors/reset', 'vendors/bootstrap-grid', 'vendors/antd', 'vendors/google-maps-react.scss';

// 3. Base stuff
@import 'base/base', 'base/fonts', 'base/typography', 'base/helpers';

// 4. Layout-related sections
@import 'layout/app', 'layout/header', 'layout/footer';

// 5. Components
@import 'components/property_card', 'components/uniquely_card';

// 6. Page-specific styles
@import
  'pages/home',
  'pages/manage-property',
  'pages/property',
  'pages/property-with-map',
  'pages/property-details.scss',
  'pages/project-detail',
  'pages/create-property',
  'pages/property-with-map',
  'pages/offer-detail',
  'pages/profile';

// 7. Themes
// @import
//   'themes/default';
