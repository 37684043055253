.border-container {
    border: 1px solid #D8D8D8;
    border-radius: 5px;
}
.text-red-500 {
    color: red;
}
.text-purple-500 {
    color: $brand-color;
}
.text-purple-300 {
color: rgba(176, 168, 255, 1) ;
}
.text-white {
    color: white;
}

.input-container  {
    border: 1px solid #979797;
    border-radius: 3px;
    &.error {
        border: 1px solid red;
    }
}
.react-tel-input .error {
    border: 1px solid red !important;
}

.react-tel-input .form-control {
    border: 1px solid #979797;
    border-radius: 3px;
    font-size: 1rem;
    padding-left: 4rem;
    height: 42px;
    width: 100%;
}

.react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 45px;
    height: 100%;
    padding: 0 12px;
    border-radius: 3px 0 0 3px;
    background-color: white;
}

.offer-calendar {
    border: 1px solid #979797;
    border-radius: 3px;
    height: 2.5rem;
}

.date-picker {
    border: none;
}

.offer-dropdown-input-container {
    top: 110%;
    left: 0;
    border: 1px solid #979797;
    border-radius: 3px;
    max-height: 300px;
    overflow: auto;
    cursor: pointer;
    ::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      background-color: transparent;
    }
  }

.border-bottom {
    border-bottom: 1px solid #d8d8d8;
}

    .text-orange {
        color: #FD9B0A;
    }
.offer-discount-label {
    color: white;
    font-size: 0.9rem;
    text-align: center;
    width: 48px;
    padding-top: 10px;
    padding-bottom: 25px;
    background-color: #FD9B0A;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 73%, 0 100%);;

    position: absolute;
    top: 0;
    right: 10px;
}

.paygroup {
    word-wrap: 230px;
}

.offer-detail__modal-overlay{
    background-color: rgba(0,0,0,0.5);
    width: 100vw;
    height: 100vh;
    z-index: 999;
    position: fixed;
  }
  .offer-detail__modal-container {
    z-index: 9999;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 430px;
    transform: translate(-50%,-50%);
    width: 50%;
    border-radius: 5px;
  }

  .attached {
      background-color: #DCF7E3;
      color: #2FA84F;
      border: 1px solid #2FA84F;
      border-radius: 3px;
  }


@media screen and (max-width: 768px) {
    .offer-detail__modal-container  {
        width: 80%;
    }
}
@media screen and (max-width: 375px) {
    .offer-detail__modal-container  {
        width: 90%;
    }
}
