.property_card {
  position: relative;

  .tooltip {
    display: none;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid $gray-color;
    background-color: $white-color;

    &--name {
      font-weight: 500;
      line-height: pxToRem(20px);
      font-size: pxToRem(14px);
      color: $black-color;
    }

    &--detail {
      line-height: pxToRem(20px);
      font-size: pxToRem(14px);
      color: $black-color;
    }
  }

  &--main {
    cursor: pointer;
    transition: trans(800ms);
    position: relative;
    background-color: $white-color;

    @include on-event {
      @include tablet-landscape {
        transform: scale(1.05) translateY(-10px);
        box-shadow: 0 28px 32px -18px rgba(0, 0, 0, 0.32);
      }
    }

    .overlay {
      &--box {
        position: absolute;
        z-index: 1;
        width: 45px;
        height: 63px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 75%, 0 100%);
        background-color: $yellow-color;
      }

      &--half_price {
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .text {
          margin-bottom: 12px;
          text-align: center;
          font-size: pxToRem(12px);
          color: $white-color;
        }
      }
    }
  }

  &--vertical {
  }

  &--horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;

    .property_card--cover {
      padding-bottom: 28.125%;
      width: 50%;
      border-radius: 8px;
    }

    .property_card--content {
      padding-left: 16px;
      width: 50%;
      border: 0;
    }
  }

  &--cover {
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;
    height: 0;
    border-radius: 8px 8px 0 0;

    .image {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &--content {
    padding: 10px 8px;
    border-radius: 0 0 8px 8px;
    border-left: 1px solid rgb(216, 216, 216);
    border-right: 1px solid rgb(216, 216, 216);
    border-bottom: 1px solid rgb(216, 216, 216);

    .price {
      margin-bottom: 22px;
      font-weight: 500;

      &--promotion {
        .og {
          margin-right: 6px;
          text-decoration: line-through;
          font-size: pxToRem(16px);
          color: rgba(176, 168, 255, 1);
        }

        .now {
          margin-right: 6px;
          font-size: pxToRem(16px);
          color: $yellow-color;
        }

        .per {
          font-size: pxToRem(12px);
          color: $gray-color;
        }
      }

      &--normal {
        .og {
          margin-right: 6px;
          font-size: pxToRem(16px);
          color: $brand-color;
        }

        .per {
          font-size: pxToRem(12px);
          color: $gray-color;
        }
      }
    }

    .spec {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      &--box {
        display: flex;
        align-items: center;
        margin-right: 25px;

        &:last-child {
          margin-right: 0;
        }
      }

      &--icon {
        margin-right: 10px;
        width: auto;
        height: 15px;
        background: none;
      }

      &--value {
        font-size: pxToRem(12px);
        color: $black-color;
      }

      &--unit {
        margin-left: 6px;
        font-size: pxToRem(12px);
        color: $black-color;
      }
    }
  }

  &--name {
    overflow: hidden;
    display: -webkit-box;
    margin-bottom: 6px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-weight: 500;
    font-size: pxToRem(14px);
    color: $black-color;
  }

  &--address {
    overflow: hidden;
    display: -webkit-box;
    margin-bottom: 20px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: pxToRem(12px);
    color: $black-color;
  }

  &--lastest {
    font-size: pxToRem(12px);
    color: $gray-color;
  }
}

.property_card-promotion_wrap {
  position: relative;

  .promotion {
    transition: trans(400ms);
    margin-bottom: 10px;
    padding: 10px 8px 6px 8px;
    border-radius: 8px;
    border: 1px solid $gray-color;
    background-color: $white-color;

    &--main {
      display: flex;
      flex-wrap: wrap;
    }

    &--detail {
      opacity: 0;
      position: absolute;
      z-index: 10;
      top: -90px;
      transition: height trans(400ms);
      overflow: hidden;
      height: 0;
      padding: 0;
      border-radius: 7px;
      border: 1px solid $gray-color;
      background-color: $white-color;

      &--show {
        opacity: 1;
        padding: 8px;
        height: auto;
      }

      &--name {
        font-weight: 500;
        line-height: pxToRem(20px);
        font-size: pxToRem(14px);
        color: $black-color;
      }

      &--detail {
        line-height: pxToRem(20px);
        font-size: pxToRem(14px);
        color: $black-color;
      }
    }

    &--brand {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: calc(100% - 45px);

      .logo {
        display: flex;
        align-items: center;
        width: 30px;
        height: auto;

        .image {
          width: 30px;
          height: auto;
          background: none;
        }
      }

      .content {
        width: calc(100% - 55px);
        margin-left: 25px;

        &--title {
          overflow: hidden;
          display: -webkit-box;
          margin-top: 6px;
          margin-bottom: -8px;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-size: pxToRem(14px);
          color: $black-color;
        }

        &--coutdown {
          display: flex;
          align-items: center;

          .icon {
            margin-top: 6px;
            margin-right: 10px;
            width: auto;
            height: 15px;
            background: none;
          }

          .text,
          .ant-statistic-content-value {
            font-size: pxToRem(14px);
            color: $black-color;
          }

          .text {
            margin-top: 8px;
            margin-left: 5px;
          }
        }
      }
    }

    &--tooltip_btn {
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 25px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 1px solid $black-color;
        background: none;
        font-size: pxToRem(12px);
        color: $black-color;
      }
    }
  }
}
