.change-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: black;
    text-decoration: underline;
}
.border-bottom {
    border-bottom: 1px solid #d8d8d8;
}
    .form-symbol {
        left: -28px;
        top: 0%;
    }

    .circle {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        margin-right: 8px;
        background-color: #d8d8d8;
    }
    .profile-input {
        border: 1px solid #d8d8d8;
        padding: 5px;
        padding-left: 20px;
        border-radius: 3px;
        &::placeholder {
            color: #979797;
            letter-spacing: 1px;
        }
    }
    .profile-textarea {
        border: 1px solid #d8d8d8;
        padding: 1rem;
        padding-left: 20px;
        border-radius: 3px;
        &::placeholder {
            color: #979797;
            letter-spacing: 1px;
        }
    }
    .password-input-error {
        border: 1px solid #EB3D2F;
        color: #EB3D2F;
        background-color: #FFE4E3;
    }
    .btn-profile {
        color: white;
        border: none;
        cursor: pointer;
        padding: 0.8rem 0;
        margin-top: 2rem;
        margin-right: 1rem;
        border-radius: 5px;
        font-size: 0.9rem;
        font-weight: lighter;
    }
        .save {
            background-color: $brand-color;
        }
        .cancel {
            background-color: #c3c3c3;
        }

    .profile-modal-overlay {
        background-color: rgba(0,0,0,0.2);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 101;
        width: 100vw;
        height: 100vh;
    }
    .close {
        background-color: transparent;
        border: none;
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
    }